import React from "react";
import { Link } from "gatsby";

import { LayoutMain } from "../layouts/layout-main/layout-main";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { useCourses } from "../hooks/use-courses";
import {
  courseList,
  courseTitle,
  courseBlock,
  courseDescription,
  courseContent,
} from "./courses.module.scss";

const CoursePage = () => {
  const courses = useCourses();

  return (
    <LayoutMain isBlogPost={false} title="Courses">
      <article className="courses">
        <h1 className="page-title">Courses</h1>
        <ul className={courseList}>
          {courses.map((course) => {
            const featuredImage = getImage(
              course.banner.childImageSharp.gatsbyImageData
            );
            return (
              <li key={course.id}>
                <section className={courseBlock}>
                  {featuredImage && (
                    <figure>
                      <Link to={`/course/${course.slug}`}>
                        <GatsbyImage image={featuredImage} alt="" />
                      </Link>
                    </figure>
                  )}
                  <h2 className={courseTitle}>{course.title}</h2>
                  <div className={courseContent}>
                    <p className={courseDescription}>{course.description}</p>
                    <div>
                      <Link to={`/course/${course.slug}`}>View Course</Link>
                    </div>
                  </div>
                </section>
              </li>
            );
          })}
        </ul>
      </article>
    </LayoutMain>
  );
};

export default CoursePage;
