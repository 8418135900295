import { useStaticQuery, graphql } from "gatsby";
import { CourseRaw } from "../utils/interfaces";
import { normalizeCourses } from "../utils/utils";

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

const getCourses = graphql`
  query {
    all: allFile(
      filter: {
        sourceInstanceName: { eq: "courses" }
        extension: { eq: "mdx" }
        childMdx: { frontmatter: { type: { eq: "course" } } }
      }
      sort: { fields: childMdx___frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          id
          childMdx {
            frontmatter {
              title
              date
              author
              category
              categorySlug
              slug
              keywords
              description
              banner {
                id
                childImageSharp {
                  id
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            excerpt(pruneLength: 300)
          }
        }
      }
    }
    published: allFile(
      filter: {
        sourceInstanceName: { eq: "courses" }
        extension: { eq: "mdx" }
        childMdx: {
          frontmatter: { type: { eq: "course" }, status: { eq: "published" } }
        }
      }
      sort: { fields: childMdx___frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          id
          childMdx {
            frontmatter {
              title
              date
              author
              category
              categorySlug
              slug
              keywords
              description
              banner {
                id
                childImageSharp {
                  id
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            excerpt(pruneLength: 300)
          }
        }
      }
    }
  }
`;

export const useCourses = () => {
  const { all, published } = useStaticQuery(getCourses);
  const courses: CourseRaw[] =
    activeEnv === "development"
      ? all.edges.map(({ node }: { node: any }) => node)
      : published.edges.map(({ node }: { node: any }) => node);

  return normalizeCourses(courses);
};
